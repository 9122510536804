@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variables.scss';

.form_wrapper {
  margin-top: 44px;
}

.form_field {
  margin-bottom: 40px;

  @include respond-to(720px) {
    margin-bottom: 32px;
  }
}

.form_button {
  @include defaultStyleButton;

  @include respond-to(820px) {
    margin-bottom: 16px;
    width: 100%;
  }
}

.form_button_registration {
  @include defaultStyleButton;
  background-color: $default-background-color;
  color: #292d32;
  margin-left: 16px;

  @include respond-to(820px) {
    width: calc(100% - 80px);
    text-align: center;
    margin-left: 0;
  }
}

.wrapper_button {
  display: flex;
  width: 100%;

  @include respond-to(820px) {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }
}

.wrapper_error {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.forgot_password {
  display: block;
  @include regularText(14px, 18px);
  text-decoration: none;
  color: $default-color-button;
  margin-bottom: 25px;
}
