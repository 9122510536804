@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.enter {
  position: fixed;
  top: 0;
  width: calc(50% - 125px);
  max-width: 721px;
  height: 100vh;
  transform: translateX(-100%);
  background-color: $default-text-white-color;
  transition: transform 0.7s ease;
  padding-top: 46px;
  padding-left: 50px;
  padding-right: 75px;
  z-index: 9999;
  overflow: auto;

  @include respond-to(1050px) {
    padding: 50px 16px;
    width: calc(50% - 32px);
  }

  @include respond-to(720px) {
    width: calc(100% - 32px);
    padding-top: 16px;
  }
}

.active {
  transform: translateX(0);
  transition: transform 0.7s ease;
}

.title {
  @include mediumText(60px, 85px);
  margin: 0;
  margin-top: 50px;

  @include respond-to(720px) {
    @include mediumText(24px, 34px);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 577px;
}

.button_back {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  @include defaultStyleButton;
  max-width: fit-content;

  @include respond-to(720px) {
    padding: 8px 25px;
  }
}

.arrow {
  margin-right: 5px;
  flex-grow: 2;
}

.wrapper_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
