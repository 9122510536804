@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;
  @include regularText(16px, 19px);
}

.field_label {
  @include mediumText(16px, 19px);
  margin-bottom: 16px;
  @include respond-to(720px) {
    @include mediumText(14px, 17px);
  }
}

.field_input {
  border-radius: 24px;
  background-color: $default-background-color;
  border: none;
  height: 62px;
  padding-left: 24px;

  @include respond-to(720px) {
    height: 46px;
  }
}

.field_input::placeholder {
  @include lightText(16px, 19px);
  color: #cacaca;

  @include respond-to(720px) {
    @include lightText(12px, 14px);
  }
}

.field_error {
  color: #ff0000;
  margin-left: 25px;
  margin-top: 10px;
}

.error {
  border: 1px solid #ff1010;
}

.eye {
  position: absolute;
  right: 20px;
  top: 56%;
  cursor: pointer;
  z-index: 9999;
}

.login_loader {
  position: absolute;
  top: 56%;
  right: 20px;
}
