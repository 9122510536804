@import '@/assets/scss/mixin.scss';

.error {
  @include lightText(14px, 17px);
  color: #ff0000;
}

.active {
  margin-bottom: 10px;
}
