@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/fonts.scss';

.default {
  @include defaultStyleButton;
  display: flex;
  font-family: 'Montserrat-Light', sans-serif;
  text-align: center;
}

.wrapper_loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
