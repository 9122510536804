@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.switcher {
  position: relative;
  max-height: 40px;
  @include mediumText(14px, 17px);
}

.languages {
  position: absolute;
  right: 0;
  background-color: $default-background-color;
  padding: 0;
  list-style-type: none;
  width: 75px;
  border-radius: 20px;
  height: 0;
  overflow: hidden;
  padding: 0;
}

.languages_element {
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $default-color-button;
  }
}

.selector {
  display: flex;
  background-color: $default-background-color;
  border-radius: 50px;
  padding: 11px 24px;
  margin-left: 20px;
  cursor: pointer;

  @include respond-to(720px) {
    padding: 10px;
    margin-left: 0;
  }
}

.selector_lang {
  text-transform: uppercase;
}

.arrow {
  margin-left: 4px;
}

.show {
  padding-top: 10px;
  height: auto;
}
