@keyframes dot {
  from {
    transform: scale(0.7);
    opacity: 1;
  }

  to {
    transform: scale(0.4);
    opacity: 0.6;
  }
}

@keyframes dot2 {
  from {
    transform: scale(0.4);
    opacity: 0.6;
  }

  to {
    transform: scale(0.7);
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 85px;
}

.dot {
  border-radius: 50%;
  animation: dot 0.7s alternate infinite;
}

.dot_middel {
  border-radius: 50%;
  animation: dot2 0.7s alternate infinite;
}
